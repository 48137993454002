<template>
  <div>
    <div class="card" v-for="call in calls" v-bind:key="call">
      <div class="card-header">
        <a :href="'/call/'+call.id">{{ call.street }}, {{ call.city }}</a>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-start flex-wrap">
          <div class="m-2" v-for="equipment in call.equipment" v-bind:key="equipment">
            <div class="card"  style="width: 18rem;">
              <div class="card-body">
                <h2>{{ equipment.short_name }}</h2>
              </div>
              <div class="card-footer" :class="{'text-bg-danger': equipment.status.id === 1, 'text-bg-success': equipment.status.id === 2,}">
                {{ equipment.status.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'DashboardView',
  components: {},
  data() {
    return {
      calls: []
    };
  },
  mounted() {
    axios.get('/calls/list')
        .then((response) => {
          this.calls = response.data;
        });
  },
  computed: {
  },
  methods: {
  },
});
</script>
