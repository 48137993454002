<script>
import axios from "axios";
import moment from "moment/moment";

export default {
  name: "CallTimeline",
  props: ['callId'],
  expose: ['getTimeline'],
  data() {
    return {
      events: []
    }
  },
  methods: {
    getTimeline() {
      axios.get('call/timeline?call_id=' + this.callId)
          .then((response) => {
            this.events = response.data
          });
    }
  },
  mounted() {
    this.getTimeline();
  },
  computed: {
    moment() {
      return moment
    }
  },
}
</script>

<template>
 <div class="card">
   <div class="card-body">
     <div class="list-group">
       <div class="list-group-item text-start" v-for="event in events" v-bind:key="event">
         {{moment(event.created_at).format('YYYY-MM-DD HH:mm')}} - {{event.message}}
       </div>
     </div>
   </div>
 </div>
</template>

<style scoped>

</style>