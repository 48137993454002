<script>
import axios from "axios";

export default {
  name: "EquipmentList",
  emits: ['update:model-value'],
  expose: ["clear"],
  props: [
    'model-value'
  ],
  data() {
    return {
      departments: [],
      checkedDepartments: [],
      checkedEquipment: []
    }
  },
  methods: {
    getEquipment() {
      axios.get('/departments')
          .then((response) => {
            this.departments = response.data;
          });
    },
    updateValue() {
      let value = [];
      for (let departmentsKey in this.departments) {
        if (this.checkedDepartments[this.departments[departmentsKey].id]) {
          let departmentObj = this.departments[departmentsKey]
          let department = {
            id: departmentObj.id,
            name: departmentObj.name,
            selectedEquipment: []
          }
          for (let equipmentKey in this.departments[departmentsKey].equipment) {
            if (this.checkedEquipment[this.departments[departmentsKey].equipment[equipmentKey].id]) {
              department.selectedEquipment.push(this.departments[departmentsKey].equipment[equipmentKey]);
            }
          }
          value.push(department);
        }
      }
      this.$emit('update:model-value', value);
    },
    clear() {
      this.checkedEquipment = [];
      this.checkedDepartments = [];
    }
  },
  watch: {
    checkedEquipment: {
      handler() {
        this.updateValue();
      },
      deep: true
    },
    checkedDepartments: {
      handler() {
        this.updateValue();
      },
      deep: true
    }
  },
  mounted() {
    this.getEquipment();
  },
}
</script>

<template>
  <div>
    <div class="row border-bottom border-1" v-for="department in departments" v-bind:key="department.id">
      <div class="col-7 col-sm-4">
        <div class="form-check">
          <input name="department" v-model="checkedDepartments[department.id]" :id="'department'+department.id" type="checkbox" :value="department.id" class="form-check-input">
          <label :for="'department'+department.id" class="form-check-label text-start w-100">{{ department['short_name'] }}</label>
        </div>
      </div>
      <div class="col">
        <div class="row" v-for="equipment in department.equipment" v-bind:key="equipment.id">
          <div class="form-check col-20 col-sm-3" v-bind:hidden="!checkedDepartments[department.id]">
            <input name="equipment" v-model="checkedEquipment[equipment.id]" :id="'equipment'+equipment.id" type="checkbox" :value="equipment['short_name']" class="form-check-input">
            <label :for="'equipment'+equipment.id" class="form-check-label text-start w-100">{{ equipment['short_name'] }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: unset;
}
</style>