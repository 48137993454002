<template>
  <section>
    <form class="m-auto col-sm-6 col-11" @submit.prevent="submit">
        <div class="mb-4 row">
            <label class="form-label col-3 col-sm-2" for="date">
                Date
            </label>
              <VueDatePicker v-model="form.date" class="col"></VueDatePicker>
        </div>
        <div class="mb-4 row">
            <label class="mb-2 col-3 col-sm-2" for="road">
                Address
            </label>
            <MapboxGeocoder
                class="col w-100"
                :access-token="accessToken"
                types="address"
                mapboxgl="geocoderSearch"
                :bbox="[-63.43580169594737, 46.07250723641602, -62.80558877960794, 46.522799895826616]"
                @mb-result="addressSelect"
            />
        </div>

        <div class="mb-4 row">
            <label class="mb-2 col-3 col-sm-2" for="call_type">
                Call Type
            </label>
            <select class="form-control col"
                   id="call_type" name="call_type" v-model="form.call_type">
              <option :value="type.id" v-for="type in callTypes" v-bind:key="type">{{type.name}}</option>
            </select>
        </div>
        <div class="row mb-4">
            <label class="col-3 col-sm-2 mb-2">
                Department
            </label>
            <div class="col">
              <EquipmentList ref="equipmentList" v-model="callEquipment"></EquipmentList>
            </div>
        </div>
        <div class="row">
            <button type="submit"
                    class="btn btn-primary">
              <span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Send It</span>
            </button>
          <button type="reset" class="btn btn-danger mt-5" @click="clear">
            Clear
          </button>
        </div>
    </form>
  </section>
  <PagesList class="mt-4"/>
</template>

<script>
import { defineComponent } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css';
import PagesList from "@/views/PagesList.vue";
import EquipmentList from "@/views/EquipmentList.vue";
import {MapboxGeocoder} from '@studiometa/vue-mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
import axios from "axios";

export default defineComponent({
  name: 'CallCreate',
  components: {EquipmentList, PagesList, VueDatePicker, MapboxGeocoder},
  data() {
    return {
      accessToken: "pk.eyJ1IjoiZGF2aWRmbHlwZWkiLCJhIjoiY200dDJubHY2MDc3NzJrcHpwaHY0MWtkbyJ9.rCf_ZwvpfR4hNAx9NM9hHg",
      geocoderSearch: null,
      selectedAddress: null,
      loading: false,
      form: {
        date: null,
        address: null,
        call_type: '',
        department: null,
        equipment: [],
      },
      callEquipment: [],
      callTypes: []
    }
  },
  methods: {
    addressSelect(result) {
      this.selectedAddress = result;
    },
    async submit() {
      this.loading = true;
      let data = this.form
      data.date = this.form.date
      data.address = {
        full_address: this.selectedAddress.result.place_name,
        long: this.selectedAddress.result.center[0],
        lat: this.selectedAddress.result.center[1],
      };

      data.department = this.callEquipment[0].id;
      data.equipment = [];

      this.callEquipment[0].selectedEquipment.forEach((equipment) => {
        data.equipment.push(equipment.id);
      });

      axios.post('/call/create', data)
          .then((response) => {
            if (response.data.id !== null) {
              this.$router.push('/call/' + response.data.id)
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    clear() {
      this.form = {
        date: null,
        road:'',
        city:'',
        call_type:'',
        department:[],
        station:'',
        equipment:'',
      };

      this.checkedDepartments = [];
    }
  },
  mounted() {
    axios.get('/call/type')
        .then((response) => {
          this.callTypes = response.data
        });
  },
});
</script>