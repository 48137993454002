<script>

import axios from "axios";
import {MapboxMap, MapboxMarker} from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import EquipmentList from "@/views/EquipmentList.vue";
import CallUpdateForm from "@/views/CallUpdateForm.vue";
import CallTimeline from "@/views/CallTimeline.vue";
import moment from "moment";

export default {
  name: "CallView",
  components: {CallTimeline, CallUpdateForm, EquipmentList, MapboxMap, MapboxMarker},
  props: {
    id: Number,
  },
  data() {
    return {
      call: {},
      accessToken: "pk.eyJ1IjoiZGF2aWRmbHlwZWkiLCJhIjoiY200dDJubHY2MDc3NzJrcHpwaHY0MWtkbyJ9.rCf_ZwvpfR4hNAx9NM9hHg",
      style: "mapbox://styles/davidflypei/cm4t2u2n7004101s281gx8hrg",
      mapCenter:[0,0],
      callLatLong: [0.0],
      callEquipment: {},
      map: {},
      addEquipment: [],
    }
  },
  created() {
    this.getCall();
  },
  methods: {
    getCall() {
      axios.get('/call/?call_id=' + this.id)
        .then((response) => {
          this.call = response.data;
          this.$refs.callTimeline.getTimeline();
        });
    },
    updateCallPosition(lat, long) {
      axios.post('/call/', {
        id: this.id,
        lat: lat,
        long: long
      })
          .then(() => {
            this.getCall();
          });
    },
    callDragged(marker) {
      let latlong = marker.target.getLngLat();
      this.updateCallPosition(latlong.lat, latlong.lng)
    },
    equipmentDragged(marker, equipmentId) {
      let latlong = marker.target.getLngLat();
      axios.post('/call/updateEquipment', {equipment_id: equipmentId,lat: latlong.lat, long:latlong.lng, })
          .then(() => {
            this.getCall();
          });
    },
    setEquipmentStatus(event, equipmentId) {
      axios.post('/call/updateEquipment', {
        equipment_id: equipmentId,
        status_id: this.callEquipment[equipmentId],
        call_id: this.id
      })
          .then(() => {
            this.getCall();
          });
    },
    mapCreated(mapboxInstance) {
      this.map = mapboxInstance

      // this.map.on('load', () => {
      //   this.map.addSource('maine', {
      //     type: 'geojson',
      //     data: {
      //       type: 'Feature',
      //       geometry: {
      //         type: 'Polygon',
      //         // These coordinates outline Maine.
      //         coordinates: [
      //           [
      //             [-63.43580169594737, 46.522799895826616],
      //             [-63.43580169594737, 46.07250723641602],
      //             [-62.80558877960794, 46.07250723641602],
      //             [-62.80558877960794, 46.522799895826616]
      //           ]
      //         ]
      //       }
      //     }
      //   });
      //
      //   this.map.addLayer({
      //     id: 'maine',
      //     type: 'fill',
      //     source: 'maine',
      //     layout: {},
      //     paint: {
      //       'fill-color': '#0080ff',
      //       'fill-opacity': 0.5
      //     }
      //   });
      //
      //   this.map.addLayer({
      //     id: 'outline',
      //     type: 'line',
      //     source: 'maine',
      //     layout: {},
      //     paint: {
      //       'line-color': '#000',
      //       'line-width': 3
      //     }
      //   });
      // });
    },
    submitAddEquipment() {
      let equipmentIds = [];

      this.addEquipment.forEach((department) => {
        department.selectedEquipment.forEach((equipment) => {
          equipmentIds.push(equipment.id);
        });
      });

      axios.post('/call/addEquipment', {
        equipment_ids: equipmentIds,
        status_id: 1,
        call_id: this.id
      })
          .then(() => {
            this.getCall();
            this.$refs.equipmentList.clear();
          });
    },
    removeEquipment(event, equipmentId){
      axios.post('/call/removeEquipment', {
        equipment_ids: [equipmentId],
        call_id: this.id
      })
          .then(() => {
            this.getCall();
          });
    },
  },
  watch: {
    call: {
      handler(newValue){
        if (typeof newValue.lat !== 'undefined' && typeof newValue.long !== 'undefined'){
          if (newValue.long !== this.callLatLong[0] || newValue.lat !== this.callLatLong[1]) {
            this.callLatLong = [newValue.long, newValue.lat];
            this.mapCenter = this.callLatLong;
          }
        } else {
          this.callLatLong = [0, 0];
        }
      },
      deep: true
    },
  },
  computed: {
    mapEquipment() {
      let equipment = [];
      this.call.equipment.forEach((value) => {
        if (value.lat && value.long) {
          equipment.push(value);
        }
      });
      return equipment;
    },
    moment() {
      return moment
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="d-flex flex-column flex-md-row">
      <div class="card col m-2">
        <div class="card-body">
          <div class="row">
            <div class="col col-4 col-lg-3 text-end">
              Date:
            </div>
            <div class="col text-start" v-if="call.street">
              {{moment(call.created_at).format('YYYY-MM-DD HH:mm')}}
            </div>
          </div>
          <div class="row">
            <div class="col col-4 col-lg-3 text-end">
              Street:
            </div>
            <div class="col text-start" v-if="call.street">
              {{ call.street }}
            </div>
          </div>
          <div class="row">
            <div class="col col-4 col-lg-3 text-end">
              City:
            </div>
            <div class="col text-start" v-if="call.city">
              {{ call.city }}
            </div>
          </div>
          <div class="row">
            <div class="col col-4 col-lg-3 text-end">
              Call Type:
            </div>
            <div class="col text-start" v-if="call.call_type">
              {{ call.call_type.name }}
            </div>
          </div>
          <div class="row">
            <div class="col col-4 col-lg-3 text-end">
              Department:
            </div>
            <div class="col text-start" v-if="call.department">
              {{call.department.name}}
            </div>
          </div>
          <div class="row">
            <div class="col col-4 col-lg-3 text-end">
              Mutual Aid:
            </div>
            <div class="col text-start" v-if="call.mutual_aid_departments">
                <div  v-for="department in call.mutual_aid_departments" v-bind:key="department">
                  {{department.name}}
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card col m-2">
        <div class="card-body">
          <MapboxMap
            style="height: 300px"
            :access-token="accessToken"
            :map-style="style"
            :center="mapCenter"
            :zoom="18"
            @mb-created="mapCreated"
          >
            <MapboxMarker :lng-lat="callLatLong" draggable="true" @mb-dragend="callDragged">
            </MapboxMarker>
            <MapboxMarker
                v-for="equipment in mapEquipment"
                :lng-lat="[equipment.long, equipment.lat]"
                v-bind:key="equipment"
                draggable="true"
                @mb-dragend="(test) => equipmentDragged(test, equipment.id)"
            >
              <span class="fs-5" :class="{'text-bg-danger':equipment.status.id === 2, 'text-bg-secondary':equipment.status.id !== 2}">{{equipment.short_name}}</span>
            </MapboxMarker>
          </MapboxMap>
        </div>
      </div>
    </div>
    <div class="card col m-2">
      <div class="card-body">
        <div class="d-flex flex-wrap">
          <div class="m-2" v-for="equipment in call.equipment" v-bind:key="equipment">
            <div class="card"  style="width: 18rem;">
              <div class="card-header" :class="{'text-bg-danger': equipment.status.id === 1, 'text-bg-success': equipment.status.id === 2,}">
                {{ equipment.status.name }}
              </div>
              <div class="card-body">
                {{equipment.department.name}}
                <h2>{{ equipment.short_name }}</h2>
              </div>
              <div class="card-footer">
                <div class="input-group mb-3">
                  <select v-model="callEquipment[equipment.id]" class="form-select col-2 form-control" aria-label="Default select example">
                    <option value="1">Enroute</option>
                    <option value="2">On Scene</option>
                    <option value="3">Returning</option>
                    <option value="4">Returned</option>
                  </select>
                  <button class="col-1 btn btn-primary form-control" @click="(event) => setEquipmentStatus(event, equipment.id)">
                    Set
                  </button>
                  <button class="btn btn-danger" v-if="equipment.status.id === 4" @click="(event) => removeEquipment(event, equipment.id)">
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <div class="card col m-2">
        <div class="card-header">
          <h5 class="text-start">Add Equipment</h5>
        </div>
        <div class="card-body">
          <div class="row">
            Select Equipment:
            <EquipmentList ref="equipmentList" v-model="addEquipment"></EquipmentList>
          </div>
          <div class="text-end">
            <button class="btn btn-primary" @click="submitAddEquipment">
              Add Equipment
            </button>
          </div>
        </div>
      </div>
      <CallUpdateForm :call-id="id" @onSubmit="getCall"></CallUpdateForm>
    </div>
    <CallTimeline ref="callTimeline" :call-id="id"></CallTimeline>
  </div>
</template>

<style scoped>

</style>