import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './modules/http'

import {createBootstrap} from 'bootstrap-vue-next'

// Add the necessary CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

const app = createApp(App);

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://192.168.2.209:8000/';  // the FastAPI backend
// axios.defaults.baseURL = 'http://localhost:8000/';  // the FastAPI backend
axios.defaults.baseURL = 'https://api.fd.3centsmckenna.ca/';  // the FastAPI backend

app.use(router);
app.use(store);
app.use(createBootstrap())
app.mount('#app');
